import { stylesheet } from 'typestyle'

const goldHover = {
        '&:hover': {
            color: '#f0b90b',
            transition: 'color 0.3s',
        },
    }

const blue = {
        '&:hover': {
            color: '#2252af',
        },
    }

const styles = stylesheet({
    App: {
        //dark gray background with silver gradient, zoomed in background image
        backgroundColor: '#1c1c1c',
        backgroundImage: 'url(bg_aperture.jpg)',
        backgroundBlendMode: 'multiply',
        // backgroundImage: 'linear-gradient(to bottom, #1c1c1c, #2c2c2c)',
        backgroundSize: 'cover',
        //scale to remove white space and border
        transform: 'scale(1.1)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "5vh",
        paddingRight: "5vh",
    },
    container: {
        //logo on left side, text on right side same height as logo. Text is 3 lines
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '800px',
        "$nest": {
            "@media screen and (max-width: 700px) and (orientation: portrait)" : {
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
    },
    logo: {
        //gold color svg with shadow aroun. Same width as the svg in the logo
        fill: '#f0b90b',
        filter: 'drop-shadow(0px 0px 10px rgba(240, 185, 11, 0.5))',
    },
    textContainer: {
        //Silver text color modern font
        color: '#c0c0c0',
        fontFamily: 'Roboto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '100%',
    },
    title: {
        //largest font size, strectht to text containers width, 1 line
        fontSize: '2rem',
        display: 'inline-block',
        width: '100%',
        textAlign: 'justify',
        margin: '0',
        transition: 'color 0.3s',
        // ...goldHover,
        // apply goldhover to self but not children. If the children is hovered set back to original color
        "$nest": {
            ...blue,
            "@media screen and (max-width: 700px) and (orientation: portrait)" : {
                textAlign: 'center',
            },
        }
    },
    ai: {
        display: 'inline-block',
        margin: '0',
        //gold color on parent hover
        "$nest": {
            ...goldHover,
        }
        // kepp original color on parent hover
    },
    subtitle: {
        //medium font size, strecth to cotianer width, 1 line
        fontSize: '1.5rem',
        width: '100%',
        textAlign: 'justify',
        margin: '0',
        filter: 'drop-shadow(0px 0px 10px rgba(240, 185, 11, 0.5))',
        "$nest": {
            "@media screen and (max-width: 700px) and (orientation: portrait)" : {
                textAlign: 'center',
            },
            ...goldHover,
        },

    },
    caption: {
        //smallest font size, strecth to cotianer width, 1 line. flipping in animation from borrom to top
        fontSize: '1rem',
        width: '100%',
        textAlign: 'justify',
        margin: '0',
        "$nest": {
            ...goldHover,
            "@media screen and (max-width: 700px) and (orientation: portrait)" : {
                textAlign: 'center',
            },
        }
    },
    svg: {
        fill: '#f0b90b',
        //drop shadow
        filter: 'drop-shadow(0px 0px 10px rgba(240, 185, 11, 0.5))',
        //same height as container
        width: '80px',
        "$nest": {
            "@media screen and (max-width: 700px) and (orientation: portrait)" : {
                width: '120px',
            },
        },
    },
})

export default styles
