import React from 'react'
import styles from './App.styles'

//dictionary dependin on the navigator language

type SupportedLanguages = 'en' | 'fr' | 'es' | 'de' | 'it' | 'pl' | 'sk' | 'cz' | 'hu' | 'si' | 'hr'
const dictionary: Record<SupportedLanguages, { subtitle: string; caption: string }> = {
  en: {
    subtitle: 'EXCHANGE PHOTOS',
    caption: '...EASILY & PRIVATELY'
  },
  fr: {
    subtitle: 'ÉCHANGEZ DES PHOTOS',
    caption: '...FACILEMENT ET EN PRIVÉ'
  },
  es: {
    subtitle: 'VENDER FOTOS',
    caption: '...FÁCIL Y PRIVADO'
  },
  de: {
    subtitle: 'FOTOS VERKAUFEN',
    caption: '...EINFACH UND PRIVAT'
  },
  it: {
    subtitle: 'VENDI FOTO',
    caption: '...FACILMENTE E IN PRIVATA'
  },
  pl: {
    subtitle: 'SPRZEDAM ZDJĘCIA',
    caption: '...ŁATWO I PRYWATNIE'
  },
  sk: {
    subtitle: 'PREDAJ FOTOGRAFIÍ',
    caption: '...JEDNODUCHO A SÚKROMNE'
  },
  cz: {
    subtitle: 'PRODÁM FOTOGRAFIÍ',
    caption: '...SNADNO A SOUKROMÉ'
  },
  hu: {
    subtitle: 'FOTÓ ÉRTÉKESÍTÉS',
    caption: '...KÖNNYEDÉN ÉS PRIVÁTAN'
  },
  si: {
    subtitle: 'PRODAJ FOTOGRAFIJE',
    caption: '...PREPROSTO IN ZASEBNO'
  },
  hr: {
    subtitle: 'PRODAJ FOTOGRAFIJE',
    caption: '...JEDNOSTAVNO IN ZASEBNO'
  }
}

function App() {
  const tld = window.location.hostname.split('.').pop() as SupportedLanguages
  const language = tld || (navigator.language.split('-')[0] as SupportedLanguages)

  const subtitle = dictionary[language]?.subtitle || dictionary['en'].subtitle
  const caption = dictionary[language]?.caption || dictionary['en'].caption

  return (
    <div className={styles.App}>
      <div className={styles.container}>
        <img src="portrait_hat.svg" className={styles.svg} alt="portraika_icon" />
        <div className={styles.textContainer}>
          <Title></Title>
          <p className={styles.subtitle}>{subtitle}</p>
          <p className={styles.caption}>{caption}</p>
        </div>
      </div>
    </div>
  )
}

function Title() {
  return (
    <p className={styles.title}>
      PORTR<p className={styles.ai}>AI</p>
      KA
    </p>
  )
}

export default App
